import * as React from 'react';
import { useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

// **** CUSTOM IMPORTS


import Copyright from './Copyright';


const Footer= (props) => {
    
  let navigate = useNavigate();

  
  return (
      <Box
        sx={{
          border: "1px solid #000",
          borderTop:  `1px solid #cccccc`,
          py: [3, 6],
          flexGrow:1,
          height:"100%",
          width:"100%",
          mb:"auto",
          backgroundColor:"#fff"
        }}
      >
        <Container maxWidth ={"md"} >
        {/* <Grid container >
            <Grid item xs={6} sm={3} sx={{textAlign:"center"}}>
                  
              <Link
                color="text.primary"
                href="#"
                underline="hover"
                sx={{ my: 1, mx: 1.5 }}
                onClick={() => { props.mainNavClick("Documentation")}}
              >
                Documentation
              </Link>
            </Grid>
          
            <Grid item xs={6} sm={3} sx={{textAlign:"center"}}>
                  
              <Link
                color="text.primary"
                href="#"
                underline="hover"
                sx={{ my: 1, mx: 1.5 }}
                onClick={() => { props.mainNavClick("YouTube")}}
              >
                Training Videos
              </Link>
            </Grid>
            <Grid item xs={6} sm={3} sx={{textAlign:"center"}}>
                  
              <Link
                color="text.primary"
                href="#"
                underline="hover"
                sx={{ my: 1, mx: 1.5 }}
                onClick={() => { props.mainNavClick("About")}}
              >
                About ICPipeline
              </Link>
            </Grid>
            <Grid item xs={6} sm={3} sx={{textAlign:"center"}}>
                  
              <Link
                color="text.primary"
                href="#"
                underline="hover"
                sx={{ my: 1, mx: 1.5 }}
                onClick={() => { props.mainNavClick("JoinTheMailingList")}}
              >
                Join Our Mailing List
              </Link>
            </Grid>
          </Grid> */}
        <Copyright mainNavClick={props.mainNavClick} sx={{ mt: 5 }}  />
        </Container>
      </Box>
    );
  }// end Footer

  export default Footer ;
  
import React, { useState, useContext } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import CircleIcon from '@mui/icons-material/Circle';

// **** CUSTOM IMPORTS

import AppContext from '../nav/AppContext';

import PageNavUpDown from '../nav/PageNavUpDown';


import DownloadFramework from "../nav/DownloadFramework";


const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-bw.png"})` ,
      backgroundRepeat: `no-repeat`,
      backgroundPositionX:"30%",
      backgroundPositionY:"-2050px",
  }
};

const PilotProgramTop = (props) => {


  const myContext = useContext(AppContext);

  console.log ("MainTop RENDERED______")


  var [copyEmailText, setCopyEmailText] = useState("copy to clipboard");
  


  return (
    <Paper elevation={0} sx={{border:"0px solid #fff6ee", backgroundColor: "#fff",border:"1px solid #fff6ee", borderRadius:2, justifyContent:"right",
          backgroundImage: {
            xs: ``,
            sm: `url(${"pipe-bw.png"})`,
          } ,
          backgroundRepeat: `no-repeat`,
          backgroundPositionX:"30%",
          backgroundPositionY:"-2050px",
          }} >
      <Grid container>
      <Grid item xs={12} sx={{ display:"flex"}}> 
      

      <Paper elevation={0} sx={{ backgroundColor: "#ffffff", border:"1px solid #9f9f9f", borderRadius:2,m:2, p:2, flexGrow:1 }} >
            <Grid container cellSpacing={2}>
              <Grid item xs={12}>
              <Typography
                component="h5"
                variant="h5"
                align="center"
                color="#000000"
              >
               ICPipeline Explorer Pilot Program
              </Typography>

              </Grid>
            </Grid>
          </Paper>
        
        </Grid>
        <Grid item xs={12} >
          
        <Grid container cellSpacing={2} sx={{pl:2, pr:2, pb:1}} >
          <Grid item xs={0} sm={6} lg={6}>
          </Grid>
            <Grid item xs={12} sm={6} lg={6}  >
              
      <Paper elevation={0} sx={{ backgroundColor: "#ffffff", border:"1px solid #9f9f9f", borderRadius:2,m:0, p:2, flexGrow:1 }} >
            <Grid container cellSpacing={2}>
              <Grid item xs={12}>
              

                <Typography component="p" variant="h7" align="center" color="#000000" sx={{pb:1, borderBottom:"1px solid #9f9f9f", mb:2}}>
                  
                  Welcome all Internet Computer Explorers. 

                  </Typography>
                  <Typography component="p" variant="body2" align="left" color="#000000" sx={{pb:3}}>
                  We're now welcoming participants to ICPipeline, the development catalyst for the Internet Computer.  
                  We believe we have a stable, robust foundation in place.  
                  We think your participation and input will add value for you, for us as well, and hopefully for the whole IC dev community.  To join us an early adopter, please follow these quick steps:
                  
                  </Typography>

                  <Tooltip title="Come and connect on Discord" enterNextDelay={300}>
                     <Box sx={{border:"1px solid #9f9f9f",  cursor:"pointer", borderRadius:2, p:2, pb:1, mb:1}}  onClick={() => { props.mainNavClick("DiscordExplorers")}}  >
                            
                      
                              <Grid container >
                                  <Grid item xs={10} sm={6} sx={{pt:0}}>
                                    <Typography variant="h6" align="left" color="text.secondary" >
                                    
                                    <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                                    Join our Discord Server
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={6} sx={{justifyContent:"left", display:"flex"}}>
      
                                        <img
                                        src={
                                        "discord-v2.svg"
                                        }
                                        srcSet={"discord-v2.svg?"}
                                        alt="Discord"
                                        loading="lazy"
                                        style={{width:"40px"}}
                                      /> 
                                  </Grid>
                                </Grid>
                          
                      </Box>
                    </Tooltip>

                  <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1}}  >
                            
                      
                            <Grid container>
                                <Grid item xs={12} sm={12} sx={{pt:0}}>
                                  <Typography variant="h6" align="left" color="text.secondary" >
                                  
                                  <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                                  Grab the "Explorer" role on the server
                                  </Typography>
                                </Grid>
                              </Grid>
                          
                        </Box>
{/* 
                        <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1}}  >
                                  
                            
                                  <Grid container>
                                      <Grid item xs={12} sm={12} sx={{pt:0}}>
                                        <Tooltip title="Download ICPipeline from GitHub" enterNextDelay={300}>
                                        <Box onClick={() => { props.mainNavClick("GitHub")}}  sx={{ backgroundColor:"#fff", border:"0px solid #9f9f9f",
                                            cursor:'pointer', 
                                            borderRadius:2, display:"flex", flexGrow:1}} >
                                            
                                        <Grid container>
                                          <Grid item xs={10} sx={{pt:.5}}>
                                          <Typography variant="h6" align="left" color="text.secondary" >
                                          
                                           <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                                          Download the Beta version and try it out
                                          </Typography>
                                    
                                          </Grid>
                                          <Grid item xs={2} >

                                          <Box sx={{pt:1, pb:1, borderRadius:2, backgroundColor:"#fff", border: "2px solid #000", width:"50px", flexGrow:1, display:"flex", justifyContent:"center"}} >
                                              <Box
                                                  component="img"
                                                  src={"GitHub-logo.png"}
                                                  alt="Logo"
                                                  sx={{ width:"100%"}}
                                              />
                                            </Box>
                                            </Grid>
                                          </Grid>
                                          </Box>
                                          </Tooltip>
                                      </Grid>
                                    </Grid>
                                
                              </Box> */}
                    
                  <Typography component="p" variant="body2" align="left" color="#000000" sx={{pb:1, pt:4}}>
                        We welcome all your comments and feedback. We will be active in Discord so bring your questions to us and we can work on them together.
                    </Typography>

              </Grid>
            </Grid>

                    
            </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={2}  sx={{pl:2, pr:2, pb:1}}>
            <Grid item xs={6}  sx={{border:"0px solid #9f9f9f", borderRadius:2, p:1, display:"flex", flexDirection:"column",  justifyContent: "center"}}>
              
            <Paper elevation={0} sx={{ backgroundColor: "#ffffff", border:"1px solid #9f9f9f", borderRadius:2,m:2, p:2, flexGrow:1 }} >
                    <Typography component="p" variant="body2" align="left" color="#000000" sx={{pb:2}}>
                      We will also have open office hours on Discord as well as will be available for "get to know you" connections on Tuesdays and Thursdays, as time allows, using Google Meet. Be sure to book a time using the Calendly link here:
  
                      </Typography>
                      <Tooltip title="We want to meet you, so go ahead and book a time." enterNextDelay={300}>
                      <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1, cursor:"pointer"}} onClick={() => { props.mainNavClick("Calendly")}}>

                      <Grid container>
                            <Grid item xs={12} sm={4} sx={{justifyContent:"right", display:"flex", pr:2}}>
                              <img
                                src={
                                "Calendly.svg"
                                }
                                srcSet={"Calendly.svg?"}
                                alt="Twitter"
                                loading="lazy"
                                style={{width:"50px"}}
                                /> 
                            </Grid>
                            <Grid item xs={12} sm={8}  sx={{justifyContent:"left", display:"flex"}}>
                              <Typography variant="h6" align="left" color="text.secondary" component="p" sx={{pt:1}}>
                              Book a Calendly Now
                              </Typography>
                            </Grid>
                          </Grid>

                      </Box>
                      </Tooltip>
              </Paper>
              </Grid>
              <Grid item xs={6} sx={{border:"0px solid #9f9f9f", borderRadius:2, p:1, display:"flex", flexDirection:"column",  justifyContent: "center"}}  >
              
            <Paper elevation={0} sx={{ backgroundColor: "#ffffff", border:"1px solid #9f9f9f", borderRadius:2,m:0,m:2, p:2, flexGrow:1 }} >

                    <Typography component="p" variant="body2" align="left" color="#000000" sx={{pb:1}}>
                    If you don't have a Discord account, or you can't find a time on our calendly, or you have any questions at all,  you can contact us directly here:
                    </Typography>
                    <Box sx={{border:"0px solid #9f9f9f", borderRadius:2, p:1, display:"flex", flexDirection:"column",  justifyContent: "center"}} >
                            
                      <Tooltip title="Send us an email with your questions" enterNextDelay={300}>
                      <Link
                        variant="button"
                        underline="hover"
                        align="center"
                        sx={{cursor:"pointer",
                            fontSize: {
                              xs: ".7em",
                              sm: "1em",
                            }
                          }}
                      
                        onClick={() => { myContext.copyToClip("framework@icpipeline.com", setCopyEmailText, copyEmailText)}}
                      >
                        framework@icpipeline.com 
                      </Link>
                      </Tooltip>
                      <Typography variant="subtitle2" align="center" color="text.secondary" sx={{fontSize:".8em"}} component="p">
                      {copyEmailText}
                      </Typography>
                      
                    </Box>
                    <Typography component="p" variant="body2" align="left" color="#000000" sx={{pb:1}}>
                    We also recommend that you
                    <Link
                                  
                                  underline="hover"
                                  align="center"
                                  sx={{p:1, cursor:"pointer"}}
                                  onClick={() => { props.mainNavClick("JoinTheMailingList")}}
                                >
                                  Join Our Mailing List
                      </Link>to keep up to date on releases, new features and opportunities to participate in the development of the platform. 
                  
                    </Typography>
                    
            </Paper>
              </Grid>
            </Grid>

      </Grid>
      </Grid>

    </Paper>


  );
} // end PilotProgramTop

export default PilotProgramTop;
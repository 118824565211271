import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';


import StarIcon from '@mui/icons-material/StarBorder';

// **** CUSTOM IMPORTS

import FullScreenImg from '../nav/FullScreenImg';
import PageNavUpDown from '../nav/PageNavUpDown';

const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges-bw.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};

const FrameworkWhy = (props) => {

  console.log ("FrameworkWhy RENDERED______")

  return (

    <Paper  elevation={0} sx={{ borderRadius:2, backgroundColor: "#fff", pt:2, pb:2, border:"1px solid #9f9f9f", }} style={styles.paperContainer}>
    
      {/* Hero unit */}
      
      <PageNavUpDown theWhereTo="Framework" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>
        
      <Box disableGutters component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff",  m: { xs:2, sm:4} ,  p: { xs:2, sm:4} }}>
        
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{
            fontSize: {
              xs: "2em",
              sm: "4em",
            }
          }}
        >
          Why is it Important?
        </Typography>

        <Typography variant="h5" align="left" color="text.primary" component="p" sx={{pt:0, pb:2,fontStyle:"italic"}}>
          Healthy development environments are fearlessly breakable.  That means they're disposably redundant and fully repeatable, while also being accessible and easy to manage at any scale.
        </Typography>
        <Typography variant="h7" align="left" color="text.primary" component="p">
          ICPipeline plays well with others.  The whole framework integrates with your existing infrastructure to simplify Internet Computer release management. 
          It fills a real gap in this ecosystem.
          It accelerates workflows and increases productivity, while easing the stresses inherent in development workflows.  
          In a nutshell, ICPipeline facilitates best-practices CI/CD for all developers and teams building on the Internet Computer.
        </Typography>

        <Box sx={{  backgroundColor: "#ffffff", pt:2 }}>
          <Grid container>
            <Grid item xs={12} >    
              <FullScreenImg theImgSrc="icpipeline-devqaprod-help.png" theAltText="Why is ICPipeline Important? - Development and Operations MUST be repeatable, accessible, and easier to manage at scale."/>
            </Grid>
            <Grid item xs={12} >
              <Typography variant="h7" align="left" color="text.primary" component="p">
                A robust developer experience -- low-friction and well-tooled, with low-to-no barriers to entry -- is essential to a thriving tech platform.
                It's an integral piece of how platforms become ecosystems, and it takes vision and hard work to bring about.
                Our opportunities wish-list has evolved directly out of our own hands-on IC journey.
                We have the experience and know-how to tackle the challenges, we're doing something about it, and this framework is the deliverable.
                We think ICPipeline moves the needle in the right direction for the Internet Computer ecosystem, and that's why it matters.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <PageNavUpDown theWhereTo="FrameworkRoadmap" theUpDown="down" theUpDownText="next section" mainNavClick={props.mainNavClick}/>

    </Paper>

  );
}// end FrameworkWhy

export default FrameworkWhy;
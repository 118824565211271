import React, { useState , useRef} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import CircleIcon from '@mui/icons-material/Circle';

// **** CUSTOM IMPORTS
import PageNavUpDown from '../nav/PageNavUpDown';

const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges-bw.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};

const FrameworkRoadmap = (props) => {

  console.log ("FrameworkRoadmap RENDERED______")

  return (

    <Paper  elevation={0} sx={{ borderRadius:2, backgroundColor: "#fff", pt:2, pb:2, border:"1px solid #9f9f9f", }} style={styles.paperContainer}>
    
    {/* Hero unit */}
    
    <PageNavUpDown theWhereTo="Framework" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>
      
    <Box disableGutters component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff",  m: { xs:2, sm:4} ,  p: { xs:2, sm:4} }}>
    
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{
            fontSize: {
              xs: "2em",
              sm: "4em",
            }
          }}
        >
          ICPipeline Roadmap
        </Typography>

        <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:2, pb:2}}>
          We think the Internet Computer is the most versatile, useable platform in the blockchain space.
          It allows us, as developers, to do things we can't do on other blockchains.
          We just need the right tools, and that's where ICPipeline comes in.
          Right now, as of today, ICPipeline delivers a serious value proposition, as a solution wrapped hand-to-glove around a clear-cut need.
          More importantly, it just works.
          We've executed a stable, reliable, complete platform, with very reasonable requirements and low barriers to entry.
          Indeed, lowering barriers to entry, playing a role in the continuing growth of this talent-rich community, is the whole idea behind ICPipeline.
        </Typography>
        <Typography>
          That said, there's a lot more work to do, and ICPipeline is in an opportunity space.
          Our priorities will be framed around real feedback from the developer community, and our modular architecture will allow us to be flexible and responsive.
          These are among the key milestones we're looking at closely for the near-to-mid term.
          We think can execute a community-driven funding and governance model that embodies certain aspects of the IC itself.
          That will be ideal, but, to be clear, we intend to execute in any event -- with funding-by-DAO or otherwise.
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} sx={{flexDirection:"column", display:"flex"}}>
            <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1, flexGrow:1}}>
              <Typography
                component="p"
                variant="h5"
                align="center"
                color="text.primary"
              >
              2021
              </Typography>

              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                Became aware of the Internet Computer around the time of its mainnet Genesis event.
              </Typography>

              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                Initial concept for ICPipeline evolved directly from identified gaps in Internet Computer DX -- the missing tools, so to speak.
              </Typography>
  
              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                Drafted a proposal for review by the Dfinity Foundation grant committee, acquired funding for first-phase execution.
              </Typography>
              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                Conceived, designed and executed a complete framework in four core, interoperating modules.  This work is continuous and ongoing for the foreseeable future.
              </Typography>

              <Tooltip title="Visit Dfinity's website and learn more about the origins of the Internet Computer ... that's where we started" enterNextDelay={300}>
                <Box onClick={() => { props.mainNavClick("Dfinity")}} sx={{ backgroundColor:"#ffffff", border:"0px solid #fff6ee", 
                  cursor:'pointer', 
                  borderRadius:2,  
                  pl:5,pr:5, display:"flex", flexGrow:1}} >
                  <img
                  src={
                  "dinfinity.png"
                  }
                  srcSet={"dinfinity.png?"}
                  alt="Dfinity Logo"
                  loading="lazy"
                  style={{width:"100%", display:"block"}}
                  />
                </Box>
              </Tooltip>
                
            </Box>
          </Grid>
        <Grid item xs={12} sm={3} sx={{flexDirection:"column", display:"flex"}}>
        
          <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1, flexGrow:1}}>
            <Typography
              component="p"
              variant="h5"
              align="center"
              color="text.primary"
            >
              Q1 2022
            </Typography>

            <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
              <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
              Released ICPipeline (pilot/ beta program) as open-source developer tooling specifically for the Internet Computer.
            </Typography>

            <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
              <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
              Currently pursuing go-to-market on all fronts including documentation; educational video; social media presence; managed calendar of bespoke consultations for networking, education and support purposes.
            </Typography>

            <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
              <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
              Continuing the hands-on engineering work of growing the platform, while balancing the time resources of a small team against other priorities (with extracurriculars demanding more cycles, by degrees).
            </Typography>
                
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} sx={{flexDirection:"column", display:"flex"}}>
            <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1, flexGrow:1}}>
                  <Typography
                  component="p"
                  variant="h5"
                  align="center"
                  color="text.primary"
                >
                  Q2 2022
                </Typography>

                <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                Vigorously leverage the energy created by interest in the product, building cachet through high-touch and real-world outcomes.  Our users will know that we are people they can do business with.
                </Typography>
                <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                <em>Listen</em>, and synthesize real feedback from real users in the real world, into a better platform.
                </Typography>
                <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                Expand the (already-modular) framework into more smaller components to facilite community participation in ongoing development.
                </Typography>
                <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                Ongoing feature-set expansion including: data archiving, snapshots and replication of canister state; more advanced Worker management tools; ports for Azure, GCP and on-prem for true go-anywhere, ICPipeline meeting user's requirements and not the other way 'round.
                </Typography>
                
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} sx={{flexDirection:"column", display:"flex"}}>
            <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1, flexGrow:1}}>
              <Typography
                component="p"
                variant="h5"
                align="center"
                color="text.primary"
              >
                End of 2022
              </Typography>
              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                Introduce a GUI frontend for the installer and other framework administration, a la Truffle/Ganache on ETH.
                More than just cosmetics (though that's important), a console format will better accommodate non-linear workflows.
                Plainly put, it needs room to stretch.
              </Typography>
              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                Establish a DAO on the Internet Computer's SNS framework, issue a governance token.
              </Typography>
              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                Refine governance protocols by which the framework's development priorities will be self-correcting, driven by the wants and needs of the community itself.
              </Typography>
              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                The DAO will comprise stakeholders whose interests align around a thriving IC ecosystem -- to which a seamless, well-tooled developer experience is integral.
              </Typography>
                
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>

  );
} //end FrameworkRoadmap

export default FrameworkRoadmap;
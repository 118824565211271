import React, { useState,useContext } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import CircleIcon from '@mui/icons-material/Circle';


import StarIcon from '@mui/icons-material/StarBorder';

// **** CUSTOM IMPORTS

import AppContext from '../nav/AppContext';

import PageNavUpDown from '../nav/PageNavUpDown';

const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};


const FrameworkConsulting = (props) => {


  const myContext = useContext(AppContext);

  console.log ("FrameworkConsulting RENDERED______")

  var [copyEmailText, setCopyEmailText] = useState("copy to clipboard");

  return (

    <Paper  elevation={0} sx={{ borderRadius:2, backgroundColor: "#CC6600", pt:2, pb:2, border:"1px solid #fff6ee", }} style={styles.paperContainer}>
      {/* Hero unit */}
      
      <PageNavUpDown theWhereTo="Framework" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>

      <Box disableGutters component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff",  m: { xs:2, sm:4} ,  p: { xs:2, sm:4} }}>
    
      <Typography
        component="h1"
        variant="h2"
        align="center"
        color="text.secondary"
        gutterBottom
        sx={{
          fontSize: {
            xs: "2em",
            sm: "4em",
          }, fontWeight:"bold"
        }}
      >
        How Can We Help You?
      </Typography>

      <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:2, pb:2}}>
        
        We're very interested in discussing your Internet Computer projects, specifically in terms how we may be  able to assist.
      
      </Typography>

      <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:2, pb:2}}>
      
        The ICArchive team can help with just about everything that both new and existing teams need.
        Whether it's specifically about ICArchive, or even things more generally related to merging, hybridizing and integrating the IC and Web3, we'd love to discuss it with you. 
        We like to get our hands dirty, and we know that comes in all shapes and sizes, so don't hesitate to touch base.
      
      </Typography>

      <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:2, pb:2}}>
        A few things that may make it easier for us to connect and engage:
      </Typography>
        
      <Grid container spacing={2}>
      
        <Grid item xs={12} sm={4} sx={{flexDirection:"column", display:"flex"}}>

            <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1, flexGrow:1}}>

              <Typography
              component="p"
              variant="h5"
              align="center"
              color="text.primary"
              >
                1. Get Started
              </Typography>

              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:2, pb:2}}>
                The best way to get started is to just go ahead and start.  Lets have a quick conversation about you, your projects and your existing workflows. 
              </Typography>
              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:5, pb: 1}}>
                During the next few weeks we'll be available for introductory meetings.  Grab a Calendly slot that works for you, and we'll look forward to seeing you there.
              </Typography>

              <Tooltip title="We want to meet you, so go ahead and book a time." enterNextDelay={300}>
                
                <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1, cursor:"pointer"}} onClick={() => { props.mainNavClick("Calendly")}}>

                  <Grid container>
                    
                    <Grid item xs={4} sm={2} sx={{justifyContent:"center", display:"flex"}}>
                      <img
                        src={
                          "Calendly.svg"
                          }
                        srcSet={"Calendly.svg?"}
                        alt="Twitter"
                        loading="lazy"
                        style={{width:"50px"}}
                      /> 
                      
                    </Grid>
                    
                    <Grid item xs={8} sm={10}  sx={{justifyContent:"center", display:"flex"}}>
                          
                      <Typography variant="h6" align="left" color="text.secondary" component="p" sx={{pt:1}}>
                        Book a Calendly Now
                      </Typography>
                        
                    </Grid>
                    
                  </Grid> 
                  
                </Box>
                
              </Tooltip>
                
              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                You can also send an email to:
              </Typography>
              
              <Box sx={{border:"0px solid #9f9f9f", borderRadius:2, p:1, display:"flex", flexDirection:"column",  justifyContent: "center"}} >
                            
                <Tooltip title="Send us an email with your questions" enterNextDelay={300}>
                      
                  <Link
                    variant="button"
                    underline="hover"
                    align="center"
                    sx={{cursor:"pointer",
                        fontSize: {
                          xs: ".7em",
                          sm: "1em",
                        }
                      }}
                  
                    onClick={() => { myContext.copyToClip("support@ICArchive.com", setCopyEmailText, copyEmailText)}}
                  >
                    support@ICArchive.com 
                  </Link>
                </Tooltip>
                      
                <Typography variant="subtitle2" align="center" color="text.secondary" sx={{fontSize:".8em"}} component="p">
                  {copyEmailText}
                </Typography>
                      
              </Box>
                
              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
                We would be excited to hear from you.
              </Typography>
            
            </Box>
          
          </Grid>
          
          <Grid item xs={12} sm={4} sx={{flexDirection:"column", display:"flex"}}>
          
            <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1, flexGrow:1}}>
              
              <Typography
                component="p"
                variant="h5"
                align="center"
                color="text.primary"
              >
                2. Assess and Plan
              </Typography>
              



              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:2, pb:1}}>
              With a general understanding of your initiative (and an NDA if you prefer), we are available for short engagements for in-depth assessment purposes.  Packages are available in half-day increments covering one to five calendar days.  We are flexible in terms of scheduling if you prefer to break things up -- e.g. day-per-week over a month, etc.

              </Typography>
              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb: 1}}>
              This can make scheduling easier while getting the most value from the hours allocated.
              </Typography>
              <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:1, mb:1, display:"flex", flexDirection:"column",  justifyContent: "center"}} >
              <Typography variant="h6" align="center" color="text.secondary" >
              We are here for you.
              </Typography>
              </Box>
              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:1, pb:2}}>
              We bring experience and expertise to application development, commerce, compliance and Agile practice.  Having applied that knowledge throughout our own Web3 journey, we may be able to assist with yours as well.
              
            </Typography>

            </Box>

          </Grid>

          <Grid item xs={12} sm={4} sx={{flexDirection:"column", display:"flex"}}>

            <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1, flexGrow:1}}>
                  
              <Typography
                component="p"
                variant="h5"
                align="center"
                color="text.primary"
              >
                3. Level Up
              </Typography>

              <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:2, pb: 1}}>
                We can integrate with your existing team or help you stand up a new one.
              </Typography>
                
              <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:1, mb:1, display:"flex", flexDirection:"column",  justifyContent: "center"}} >
              
                <Typography variant="h6" align="center" color="text.secondary" >
                  Services we can provide:
                </Typography>
              
              </Box>
              
              <Typography variant="subtitle2" align="left" color="text.primary" sx={{p:1}}>
                
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                  Architecture, Development and Design
                
              </Typography>
              
              <Typography variant="subtitle2" align="left" color="text.primary" sx={{p:1}}>
                
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                  Program Management
                
              </Typography>
              
              <Typography variant="subtitle2" align="left" color="text.primary" sx={{p:1}}>
              
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                  Marketing & Content Creation
            
              </Typography>
              
              <Typography variant="subtitle2" align="left" color="text.primary" sx={{p:1}}>
              
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                  SEM / SEO
              </Typography>
                
              <Typography variant="subtitle2" align="left" color="text.primary" sx={{p:1}}>
                
                <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                  Org Planning, Talent Screening and Acquisition
                
              </Typography>

              <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:1, mb:1, display:"flex", flexDirection:"column",  justifyContent: "center"}} >
              
                <Typography variant="h6" align="center" color="text.secondary" >
                  We want to talk to you.
                </Typography>
              </Box>
            
            </Box>
          
          </Grid>
        
        </Grid>

      </Box>

    </Paper>

  );
} // end FrameworkConsulting

export default FrameworkConsulting;
import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';


import StarIcon from '@mui/icons-material/StarBorder';

// **** CUSTOM IMPORTS

import FullScreenImg from '../nav/FullScreenImg';
import PageNavUpDown from '../nav/PageNavUpDown';

const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};

const FrameworkWhat = (props) => {

  console.log ("FrameworkWhat RENDERED______")

  return (

    <Paper  elevation={0} sx={{ borderRadius:2, backgroundColor: "#CC6600", pt:2, pb:2, border:"1px solid #fff6ee", }} style={styles.paperContainer}>
      {/* Hero unit */}
      
      <PageNavUpDown theWhereTo="Framework" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>

      <Box disableGutters component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff",  m: { xs:2, sm:4} ,  p: { xs:2, sm:4} }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.secondary"
          gutterBottom
          sx={{
            fontSize: {
              xs: "2em",
              sm: "4em",
            }
            , fontWeight:"bold"
          }}
        >
          What is ICArchive?
        </Typography>

        <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{pt:0, pb:2,fontStyle:"italic", fontWeight:"bold"}}>
        Backups; rollbacks; disaster recovery; data consistency for QA; retention policy; compliance -- all built expressly for the Internet Computer.
        
        </Typography>
      
        <Paper elevation={0} sx={{ backgroundColor: "#ffffff", border:"0px solid #9f9f9f", borderRadius:2, m:"auto", mt:2, mb:2, width:"65%", p:2,  justifyContent:"center"}} >      
          <FullScreenImg theImgSrc="icarchive-yourapp-what.png" theAltText="What is ICArchive? - ICArchive is a backup, DR and CI solution built expressly for the Internet Computer. "/>
        </Paper>

        <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pb:2}}>
        A painless one-time setup enables any canister d'app for one-click archive/restore of its canister state data.  
        It's easy to maintain a browsable library of point-in-time snapshots of your d'app's canister state.  
        Archived snapshots are in a simple file format suited for low-cost storage almost anywhere.  
        Restore by just selecting a snapshot and pushing it to a designated target canister.

        </Typography>
        <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pb:2}}>
        ICArchive is self-contained and non-invasive.  It requires no changes to your existing code base, or to the existing objects/types in your canister data schema.
        </Typography>
      
      </Box>

      <PageNavUpDown theWhereTo="FrameworkHelp" theUpDown="down" theUpDownText="next section" mainNavClick={props.mainNavClick}/>
      


    </Paper>

  );
} // end FrameworkWhat

export default FrameworkWhat;
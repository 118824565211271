import React, { useState , useContext} from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import CircleIcon from '@mui/icons-material/Circle';

// **** CUSTOM IMPORTS
import AppContext from '../nav/AppContext';
import PageNavUpDown from '../nav/PageNavUpDown';

const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges-bw.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};


const AboutHelp = (props) => {

  const myContext = useContext(AppContext);
  console.log ("AboutHelp RENDERED______")

    var [copyEmailText, setCopyEmailText] = useState("copy to clipboard");

  const copyToClip = async(whatToCopy,setStateVar,newStateVal)=> {
                                          
    await navigator.clipboard.writeText(whatToCopy);
    console.log ("whatToCopy: ", whatToCopy)
    setStateVar ("copied")
    await sleep (2000);
    console.log ("copied");
    setStateVar (newStateVal);
  }

  return (
  
    <Paper  elevation={0} sx={{ borderRadius:2, backgroundColor: "#fff", pb:2, pt:2, border:"1px solid #9f9f9f", }} style={styles.paperContainer}>
    {/* Hero unit */}
      
    <PageNavUpDown theWhereTo="About" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>

    <Box disableGutters component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff", m: { xs:2, sm:4} ,  p: { xs:2, sm:4} }}>
      
      <Typography
        component="h1"
        variant="h2"
        align="center"
        color="text.primary"
        gutterBottom
        sx={{
          fontSize: {
            xs: "2em",
            sm: "4em",
          }
        }}
      >
        Need Help?
      </Typography>

      <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:2, pb:2}}>
        The ICPipeline team has been working with Internet Computer engineering pioneers for the last year or so and would like to share 
        what we have learned. Adding this experience to our history of building distributed digital platforms as entreprenuers and driving 
        Fortune 500 digital transformations, we can provide a comprehensive and thoughtful perspective on the growth and impact of blockchain technologies,
        Web3, and specifically the Internet Computer. Download the ICPipeline Framework today and we’ll help your team get started.
      </Typography>
        
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={6}  sx={{display:"flex" }}>

          <Grid container sx={{border:"1px solid #9f9f9f", borderRadius:2, flexGrow:1 }}>
            
            <Grid item xs={12} sx={{flexGrow:1}}>

              <Box sx={{ p:2, flexDirection:"column",  flexGrow:1}} >
                
                <Grid container>
                  
                  <Grid item xs={12}>
                    
                    <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:1, mb:1,   justifyContent: "center"}} >
                    
                      <Typography variant="h6" align="center" color="text.secondary" >
                        If You ...
                      </Typography>
                    
                    </Box>
                    
                    <Typography variant="subtitle2" align="left" color="text.primary" sx={{p:1}}>
                    
                      <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                        If you're just getting started with the Internet Computer and need some guidance.
                      </Typography>
                      <Typography variant="subtitle2" align="left" color="text.primary" sx={{p:1}}>
                      <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                        If you have one or more IC project in-flight.
                      </Typography>
                      <Typography variant="subtitle2" align="left" color="text.primary" sx={{p:1}}>
                      <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                        Have a project in production and are trying to establish a solid deploymnent and testing pipeline.
                      </Typography>
                      <Typography variant="subtitle2" align="left" color="text.primary" sx={{p:1}}>
                      <CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />
                        Need help with the framework, trying to understand concepts/vocabulary, etc.
                        
                    </Typography>

                    <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:1, mb:1, display:"flex", flexDirection:"column",  justifyContent: "center"}} >
                    
                      <Typography variant="h6" align="center" color="text.secondary" >
                        We want to talk to you.
                      </Typography>
                      
                    </Box>
                    
                  </Grid>
                
                </Grid>
                
              </Box>
            
            </Grid>
            
          </Grid>
          
        </Grid>
          
      <Grid item xs={12} sm={6} sx={{display:"flex" }} >

      <Grid container sx={{border:"1px solid #9f9f9f", borderRadius:2, flexGrow:1 }}>
        
        <Grid item xs={12}>
        </Grid>
              
        <Grid item xs={12} sx={{display:"flex"}}>
                
      <Box sx={{border:"0px solid #9f9f9f" , borderRadius:2, p:2, flexGrow:1 }} >
                
        <Grid container>
          
          <Grid item xs={12}>
            
            <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1, display:"flex", flexDirection:"column",  justifyContent: "center"}} >
                            
              <Tooltip placement="top" title="Send us an email with your questions" enterNextDelay={300}>
                
                <Link
                  variant="button"
                  underline="hover"
                  align="center"
                  sx={{
                    fontSize: {
                      xs: ".7em",
                      sm: "1em",
                    }
                  }}
                  onClick={() => { myContext.copyToClip("support@icpipeline.com", setCopyEmailText, copyEmailText)}}
                >
                        
                support@icpipeline.com 
                      
              </Link>
                      
              </Tooltip>
              
              <Typography variant="subtitle2" align="center" color="text.secondary" sx={{fontSize:".8em"}} component="p">
                {copyEmailText}
              </Typography>
                      
            </Box>
                  
          </Grid>
          
          <Grid item xs={12} sx={{display:"flex"}}>
            
            <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1, flexGrow:1 }}  >
                            
              <Grid container>
              
                <Grid item xs={0} sm={2} sx={{}}>
                </Grid>
                
                <Grid item xs={12} sm={4} sx={{pt:1}}>
                
                  <Typography variant="h6" align="center" color="text.secondary" >
                              on social: 
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2} sx={{justifyContent:"center", display:"flex"}}>

                            <Tooltip title="Come and connect on Discord" enterNextDelay={300}>
                                <Link
                                  variant="button"
                                  underline="hover"
                                  align="center"
                                  sx={{ cursor:"pointer"}}
                                  onClick={() => { props.mainNavClick("Discord")}}
                                >
                                  <img
                                  src={
                                  "discord-v2.svg"
                                  }
                                  srcSet={"discord-v2.svg?"}
                                  alt="Discord"
                                  loading="lazy"
                                  style={{width:"50px"}}
                                /> 
                                </Link>
                            </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={2} sx={{justifyContent:"center", display:"flex"}} >

                        <Tooltip title="Follow our newly established nonsense" enterNextDelay={300}>
                            <Link
                              variant="button"
                              underline="hover"
                              align="center"
                              sx={{}}
                              onClick={() => { props.mainNavClick("Twitter")}}
                            >
                              <img
                              src={
                              "twitter_circle-512.png"
                              }
                              srcSet={"twitter_circle-512.png?"}
                              alt="Twitter"
                              loading="lazy"
                              style={{width:"50px", cursor:"pointer"}}
                              /> 
                              </Link>
                          </Tooltip>
                            </Grid>
                            <Grid item xs={0} sm={2} sx={{}}>
                              
                            </Grid>
                          </Grid>
                      
                    </Box>
                  </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:5, pb: 1}}>
                       For the next few weeks, we will be available for 15 min "get to know you" sessions.
                      </Typography>
                      <Tooltip title="We want to meet you, so go ahead and book a time." enterNextDelay={300}>
                      <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:2, mb:1, cursor:"pointer"}} onClick={() => { props.mainNavClick("Calendly")}}>

                      <Grid container>
                            <Grid item xs={0} sm={2} sx={{}}>
                              
                            </Grid>
                            <Grid item xs={12} sm={2} sx={{justifyContent:"center", display:"flex"}}>
                              <img
                                src={
                                "Calendly.svg"
                                }
                                srcSet={"Calendly.svg?"}
                                alt="Twitter"
                                loading="lazy"
                                style={{width:"50px"}}
                                /> 
                            </Grid>
                            <Grid item xs={12} sm={6}  sx={{justifyContent:"center", display:"flex"}}>
                              <Typography variant="h6" align="left" color="text.secondary" component="p" sx={{pt:1}}>
                              Book a Calendly Now
                              </Typography>
                            </Grid>
                            <Grid item xs={0} sm={2} sx={{}}>
                              
                            </Grid>
                          </Grid>

                      </Box>
                      </Tooltip>
                    </Grid>
                </Grid>
                </Box>
              </Grid>
            </Grid>

          </Grid>
        </Grid>

      </Box>


      <PageNavUpDown theWhereTo="AboutWho" theUpDown="down" theUpDownText="next section" mainNavClick={props.mainNavClick}/>

    </Paper>

  );
} //end AboutHelp

export default AboutHelp;
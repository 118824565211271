
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

const DownloadFramework = (props) => {


  var theBackground = props.theBackground ? props.theBackground : "#ffffff" ;
  var theText = props.theText ? props.theText : "#000000" ;
  var theBorder = props.theBorder ? props.theBorder : "#9f9f9f" ;

    return (
      <Tooltip title="Show Readme for Module" enterNextDelay={300}>
      <Box onClick={() => { props.showReadmeClick()}}   sx={{ backgroundColor:theBackground, border:"1px solid ", 
          borderColor:theBorder,
          cursor:'pointer', 
          borderRadius:2,  mb:1,
          p:1,pl:2, display:"flex", flexGrow:1}} >
          
      <Grid container >
      <Grid item xs={2} >
        <Box sx={{pt:1, pb:1, borderRadius:2, backgroundColor:"#fff", border: "2px solid #000", width:"50px", flexGrow:1, display:"flex", justifyContent:"center"}} >
        <AutoStoriesIcon  />
        </Box>
        </Grid>
        <Grid item xs={10} sx={{pt:1.5}}>
        <Typography variant="h7" color="text.primary" sx={{pl:2, fontSize: {xs:".8em", sm:'1em'}}} >
        View the README
        </Typography>
  
        </Grid>
        </Grid>
        </Box>
        </Tooltip>

    );
  }// end DownloadFramework

  export default DownloadFramework ;
  
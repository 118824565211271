
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';



const PipeDivider = (props) => {
  
const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges.png"})` ,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: `30.1%`,
  }
};

    var theHeight = props.theHeight ? props.theHeight : "130px" ;
    
    //console.log ("theHeight: ", theHeight);
    
    return (
      
      <Box ref={props.refProp} sx={{height:theHeight, color:"#fff"}} style={styles.paperContainer}>
        
      </Box>
    );
  }// end PipeDivider

  export default PipeDivider ;
  
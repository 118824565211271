import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';


import StarIcon from '@mui/icons-material/StarBorder';
import { borderRadius } from '@mui/system';

// **** CUSTOM IMPORTS


import GetStarted from "../nav/GetStarted";
import LoginICPMNow from "../nav/LoginICPMNow";



const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-bw.png"})` ,
      backgroundRepeat: `no-repeat`,
      backgroundPositionX:"30%",
      backgroundPositionY:"-2050px",
      height:"460px",
  }
};

const DocumentationTop = (props) => {



  console.log ("MainTop RENDERED______")

  const launchDfinity = () => {
    window.open ("https://dfinity.org/", "_blank" );
  };



  return (
    <Paper elevation={0} sx={{border:"0px solid #fff6ee", pb:40, backgroundColor: "#fff",border:"1px solid #fff6ee", borderRadius:2, justifyContent:"right",
          backgroundImage: {
            xs: ``,
            sm: `url(${"pipe-bw.png"})`,
          } ,
          backgroundRepeat: `no-repeat`,
          backgroundPositionX:"30%",
          backgroundPositionY:"-2050px",
          height: `560px`
          }} >
      <Grid container>
      <Grid item xs={12} sx={{ display:"flex"}}> 
      

      <Paper elevation={0} sx={{ backgroundColor: "#ffffff", border:"1px solid #9f9f9f", borderRadius:2,m:2, p:2, flexGrow:1 }} >
            <Grid container cellSpacing={2}>
              <Grid item xs={12}>
              <Typography
                component="h5"
                variant="h5"
                align="center"
                color="#000000"
              >
                Documentation
              </Typography>

              </Grid>
            </Grid>
          </Paper>
        
        </Grid>
        <Grid item xs={12} >
        <Grid container cellSpacing={2} sx={{pl:2, pr:2, pb:1}} >
          <Grid item xs={0} sm={6} lg={8}>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
            
          <Paper elevation={0} sx={{ backgroundColor: "#ffffff", border:"1px solid #9f9f9f", borderRadius:2, mt:2, mb:2,  p:2, display:"flex"}} >
            <Grid container cellSpacing={2}>
              <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("DocumentationReplicator")}} fullWidth variant="outlined" sx ={{mb:1, backgroundColor:"#ffffff"}}>
                  Replicator
                </Button>

              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("DocumentationComplete")}} fullWidth variant="outlined" sx ={{mb:1, backgroundColor:"#ffffff"}}>
                  Framework Installer
                </Button>

              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("DocumentationICPM")}} fullWidth variant="outlined" sx ={{mb:1}}>
                  ICPipeline Manager (ICPM)
                </Button>

              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("DocumentationUplink")}}fullWidth variant="outlined" sx ={{mb:1}}>
                  upLink (nodeJS)
                </Button>

              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("DocumentationWorkerdocker")}} fullWidth variant="outlined" sx ={{mb:1}}>
                  Worker
                </Button>

              </Grid>
            </Grid>
          </Paper>
          </Grid>
        </Grid>

      </Grid>
      <Grid item xs={12} sx={{}}>
        <Grid container cellSpacing={2} sx={{pl:2, pr:2, pb:1}} >
          <Grid item xs={0} sm={6} lg={8}>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
            
          <LoginICPMNow mainNavClick={props.mainNavClick}/>
            </Grid>
            </Grid>
        </Grid>
      
      </Grid>
      <Grid item xs={12} sx={{}}>
        <Grid container cellSpacing={2} sx={{pl:2, pr:2, pb:1}} >
          <Grid item xs={0} sm={6} lg={8}>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
            
          <GetStarted mainNavClick={props.mainNavClick}/>
            </Grid>
            </Grid>
        </Grid>

    </Paper>


  );
} // end DocumentationTop

export default DocumentationTop;
import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';


import StarIcon from '@mui/icons-material/StarBorder';

// **** CUSTOM IMPORTS
import GetStarted from '../nav/GetStarted'



const FrameworkTop = (props) => {



  console.log ("FrameworkTop RENDERED______")

  const launchDfinity = () => {
    window.open ("https://dfinity.org/", "_blank" );
  };
  
  const launchGitHub = () => {
    window.open ("https://github.com/icpipeline-framework", "_blank" );
  };
  


  return (
    <Paper elevation={0} sx={{border:"0px solid #fff6ee", pb:40, backgroundColor: "#cc6600",border:"1px solid #fff6ee", borderRadius:2, justifyContent:"right",
          backgroundImage: {
            xs: ``,
            sm: `url(${"icarchive-mainimage.png"})`,
          } ,
          backgroundRepeat: `no-repeat`,
          backgroundPositionX:"20%",
          backgroundPositionY:"50%",
          height: `550px`
          }} >
      <Grid container>
      <Grid item xs={12} sx={{ display:"flex"}}> 
      
      <Paper elevation={0} sx={{ backgroundColor: "#cc6600", border:"1px solid #fff6ee", borderRadius:2,m:2, p:2, flexGrow:1 }} >
            <Grid container cellSpacing={2}>
              <Grid item xs={12}>
              <Typography
                component="h5"
                variant="h5"
                align="center"
                color="#ffffff"
              >
                Welcome to ICArchive
              </Typography>

              </Grid>
            </Grid>
          </Paper>
        
        </Grid>
        <Grid item xs={12} >

        <Grid container cellSpacing={2} sx={{pl:2, pr:2, pb:1}} >
          <Grid item xs={0} sm={6} lg={8}>
          </Grid>
            <Grid item xs={12} sm={6} lg={4}  >
          <Paper elevation={0} sx={{ backgroundColor: "#cc6600", border:"1px solid #fff6ee", borderRadius:2, mt:2, mb:0,  p:2, display:"flex", float:"right"}} >
            <Grid container cellSpacing={2}>
              <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("FrameworkWhat")}} color="secondary" fullWidth variant="contained" sx ={{mb:1}}>
                  What is ICArchive?
                </Button>

              </Grid>
              {/* <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("FrameworkHow")}} color="secondary" fullWidth variant="contained" sx ={{mb:1}}>
                  How does it Work?
                </Button>

              </Grid> */}
              {/* <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("FrameworkWhy")}} color="secondary" fullWidth variant="contained" sx ={{mb:1}}>
                  Why is it Important?
                </Button>

              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("FrameworkRoadmap")}} color="secondary" fullWidth variant="contained" sx ={{mb:1}}>
                  Roadmap
                </Button>

              </Grid> */}
              <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("FrameworkHelp")}} color="secondary" fullWidth variant="contained" sx ={{mb:1}}>
                  Connect and get Help
                </Button>

              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("FrameworkConsulting")}} color="secondary" fullWidth variant="contained" sx ={{mb:1}}>
                  Consulting Services
                </Button>

              </Grid>
            </Grid>
          </Paper>
          </Grid>
        </Grid>

      </Grid>
      <Grid item xs={12} sx={{}}>
        <Grid container cellSpacing={2} sx={{pl:2, pr:2, pt:1, pb:1}}>
          <Grid item xs={0} sm={6} lg={8}>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
          <GetStarted mainNavClick={props.mainNavClick} theBackground="#cc6600" theBorder="#fff6ee" theText="#fff"/> 
            </Grid>
            </Grid>
        </Grid>
      <Grid item xs={12}>
        <Grid container cellSpacing={2} sx={{pl:2, pr:2,pt:1, pb:1}}>
          <Grid item xs={0} sm={6} lg={8}>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
          <Tooltip title="Visit Dfinity's website and learn more about the origins of the Internet Computer ... that's where we started" enterNextDelay={300}>
          <Box onClick={() => { props.mainNavClick("Dfinity")}}  sx={{ backgroundColor:"#cc6600", border:"1px solid #fff6ee", 
              cursor:'pointer', 
              borderRadius:2,  mb:2, 
              p:1,pt:1, display:"flex", flexGrow:1}} >
              
          <Grid container>
          <Grid item xs={2} sx={{pt:1}} >

            <img
            src={
            "dinfinity.png"
            }
            srcSet={"dinfinity.png?"}
            alt="Dfinity Logo"
            loading="lazy"
            style={{width:"100%", display:"block"}}
            />
            </Grid>
            <Grid item xs={10} >
            <Typography variant="subtitle2" color={"#fff6ee"} sx={{pl:2, fontStyle:"italic"}} >
            Development grant recipients from the Dfinity Foundation.
            </Typography>

            </Grid>
            </Grid>
            </Box>
            </Tooltip>
          </Grid>
          </Grid>
        </Grid>
        </Grid>

    </Paper>

  );
}// end FrameworkTop

export default FrameworkTop;

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const PageNavUpDown = (props) => {


  var theWhereTo = props.theWhereTo ? props.theWhereTo : "Home" ;
  var theUpDown = props.theUpDown ? props.theUpDown : "up" ;
  var theUpDownText = props.theUpDownText ? props.theUpDownText : "back to top" ;
  var displayArrow = [
    <ArrowDropUpIcon key={1} fontSize="large" sx={{pr:1,pl:1, float:"right", color:"#fff6ee"}}/>
  ];
  var tooltipPlacement = "top-end" ;
  if (theUpDown == "down") { 
   displayArrow = [
      <ArrowDropDownIcon key={1}  fontSize="large" sx={{pr:1, pl:1, float:"right", color:"#fff6ee"}}/>
    ];
    
   tooltipPlacement = "bottom-end" ;
  } 
    return (        
    
      <Grid container>             
        <Grid item xs={0} >
        </Grid>         
        <Grid item xs={12} sx={{display:"flex", justifyContent:"right", float:"right"}}>

          <Tooltip title={theUpDownText} enterNextDelay={300} placement={tooltipPlacement}>
          <Box disableGutters sx={{ cursor:"pointer", flexDirection:"row", pr:1,width:"100%",  border:"0px solid #000000"}} onClick={() => { props.mainNavClick(theWhereTo)}} >
          {displayArrow}
          <Typography variant="subtitle2" align="center"  color="#fff6ee" sx={{ fontStyle:"italic", float:"right", pt:1}} >
          {theUpDownText} 
          </Typography>
          </Box>
          </Tooltip>

        </Grid> 
      </Grid>
    );
  }// end PageNavUpDown

  export default PageNavUpDown ;
  

import * as React from 'react';

import { useLocation } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { green } from '@mui/material/colors';


const MainAppBar = (props) => {

  let location = useLocation();
  var colorActive = "#ffffff";
  var colorNotActive = "#ffc790";

  var colorFramework=colorActive;
  var colorAbout=colorNotActive;
  var colorDocumentation=colorNotActive;

  var displayNav = [
    
        <Box key={1} sx={{textAlign:"right", width:"100%" }}>
          <Tooltip title="What is ICArchive?" enterNextDelay={300}>
          <Link
            variant="button"
            color="#fff"
            href="#"
            underline="hover"
            sx={{ my: 1, mx: 1.5 }}
            onClick={() => { props.mainNavClick("FrameworkWhat")}}
          >
            What
          </Link>
          </Tooltip>
          {/* <Tooltip title="How does ICArchive work?" enterNextDelay={300}>
          <Link
            variant="button"
            color="#fff"
            href="#"
            underline="hover"
            sx={{ my: 1, mx: 1.5  }}
            onClick={() => { props.mainNavClick("FrameworkHow")}}
          >
            How
          </Link>
          </Tooltip> */}
          {/* <Tooltip title="Why is ICArchive important?" enterNextDelay={300}>
          <Link
            variant="button"
            color="#fff"
            href="#"
            underline="hover"
            sx={{ my: 1, mx: 1.5  }}
            onClick={() => { props.mainNavClick("FrameworkWhy")}}
          >
            Why
          </Link>
          </Tooltip>
          <Tooltip title="The Product Plan of Attack" enterNextDelay={300}>
          <Link
            variant="button"
            color="#fff"
            href="#"
            underline="hover"
            sx={{ my: 1, mx: 1.5 }}
            onClick={() => { props.mainNavClick("FrameworkRoadmap")}}
          >
            Roadmap
          </Link>
          </Tooltip> */}
          <Tooltip title="Get Help and Answer Questions" enterNextDelay={300}>
          <Link
            variant="button"
            color="#fff"
            href="#"
            underline="hover"
            sx={{ my: 1, mx: 1.5 }}
            onClick={() => { props.mainNavClick("FrameworkHelp")}}
          >
            Help
          </Link>
          </Tooltip>
          <Tooltip title="How does ICArchive work?" enterNextDelay={300}>
          <Link
            variant="button"
            color="#fff"
            href="#"
            underline="hover"
            sx={{ my: 1, mx: 1.5  }}
            onClick={() => { props.mainNavClick("FrameworkConsulting")}}
          >
            Consulting Services
          </Link>
          </Tooltip>
        </Box>
        ] ;

    if (location.pathname == "/about" ) {

      colorFramework=colorNotActive;
      colorAbout=colorActive;
      colorDocumentation=colorNotActive;

      displayNav = [
        <Box key={1} sx={{textAlign:"right", width:"100%" }}>
          <Tooltip title="Get Help here" enterNextDelay={300}>
          <Link
            variant="button"
            color="#fff"
            href="#"
            underline="hover"
            sx={{ my: 1, mx: 1.5 }}
            onClick={() => { props.mainNavClick("AboutHelp")}}
          >
            Help
          </Link>
          </Tooltip>
          <Tooltip title="Who is ICArchive?" enterNextDelay={300}>
          <Link
            variant="button"
            color="#fff"
            href="#"
            underline="hover"
            sx={{ my: 1, mx: 1.5  }}
            onClick={() => { props.mainNavClick("AboutWho")}}
          >
            Who
          </Link>
          </Tooltip>
          <Tooltip title="Hire our team to help you." enterNextDelay={300}>
          <Link
            variant="button"
            color="#fff"
            href="#"
            underline="hover"
            sx={{ my: 1, mx: 1.5  }}
            onClick={() => { props.mainNavClick("AboutConsulting")}}
          >
            Consulting
          </Link>
          </Tooltip>
        </Box>
        ] ;
    } // end if about 



    if (location.pathname == "/documentation" ) {
      colorFramework=colorNotActive;
      colorAbout=colorNotActive;
      colorDocumentation=colorActive;

      displayNav = [
        <Box key={1} sx={{textAlign:"right", width:"100%" }}>
          <Tooltip title="Learn how to install your ICArchive Framework" enterNextDelay={300}>
          <Link
            variant="button"
            color="#fff"
            href="#"
            underline="hover"
            sx={{ my: 1, mx: 1.5 }}
            onClick={() => { props.mainNavClick("DocumentationComplete")}}
          >
            Installer
          </Link>
          </Tooltip>
          <Tooltip title="Learn more about the ICArchive Manager dApp" enterNextDelay={300}>
          <Link
            variant="button"
            color="#fff"
            href="#"
            underline="hover"
            sx={{ my: 1, mx: 1.5  }}
            onClick={() => { props.mainNavClick("DocumentationICPM")}}
          >
            ICPM
          </Link>
          </Tooltip>
          <Tooltip title="Learn more about the ICArchive Uplink NodeJS module" enterNextDelay={300}>
          <Link
            variant="button"
            color="#fff"
            href="#"
            underline="hover"
            sx={{ my: 1, mx: 1.5  }}
            onClick={() => { props.mainNavClick("DocumentationUplink")}}
          >
            Uplink
          </Link>
          </Tooltip>
          <Tooltip title="Learn more about the ICArchive Worker (docker)" enterNextDelay={300}>
          <Link
            variant="button"
            color="#fff"
            href="#"
            underline="hover"
            sx={{ my: 1, mx: 1.5 }}
            onClick={() => { props.mainNavClick("DocumentationWorkerdocker")}}
          >
            Worker
          </Link>
          </Tooltip>
          </Box>
        ] ;
    }// end if documentation


  
    return (
      
      <AppBar
      position="static"
      color="default"
      elevation={4}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            position:"fixed", 
            zIndex:10,
            backgroundColor:"#c60"}}
    >
      <Toolbar sx={{ flexWrap: 'wrap', display:"flex", justifyContent:"center",  backgroundColor:"#cc6600" }}>

      <Grid container sx={{border:"0px solid #000", maxWidth:"1300px"}}>
        
        {/* 

        <Grid item xs={12} sm={6} sx={{
                                    p: {
                                        xs:0 ,
                                        md:2,
                                    }}}>
          
              <Box
                  onClick={() => { props.mainNavClick("Home")}}
                  component="img"
                  sx={{
                    content: {
                        xs: `url("ICArchive_logo_white_105.png")`,//img src from xs up to md
                        md: `url("ICArchive_logo_white_105.png")`,  //img src from md and up
                    },
                    cursor:"pointer"
                    , width: {
                      xs: "250px",
                      md: "320px",
                    }
                  }}
                  alt="Logo"
              />
        </Grid> */}

      <Grid item xs={12} sm={6} sx={{
                          display:"flex", justifyContent:"left",pt: {
                              xs:0 ,
                              md:2,
                          }}}>
                            
          <Grid container onClick={() => { props.mainNavClick("Home")}} sx={{cursor:"pointer", width:"230px",height: "65px", border:"0px solid #000"}}>
            <Grid item xs={12} sx={{border:"0px solid #00ff00"}}>
              <Typography align="center" color="#fff6ee" sx={{fontWeight: 'bold',pt:1, pb:.5,pl:1, m:0, lineHeight:1, fontSize:"2.5em"}} component="h4" variant="h4">
              ICArchive
              <Typography align="center" color="#fff6ee" sx={{fontSize:".2em"}}  variant="subtitle">TM</Typography>
              </Typography>
          </Grid>
          <Grid item xs={12} sx={{display:"flex", justifyContent: "center"}}>
          <Box sx={{pt:1,
                  backgroundImage: {
                    xs: ``,
                    sm: `url("logo-color-underline.png")`,
                  } ,
                  backgroundRepeat: `no-repeat`,
                  backgroundSize:"contain", width:"220px"}} >
            </Box>
          </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} sx={{
                    pt: {
                        xs:0 ,
                        md:4,
                    }}}>
        <nav>
        <Grid container sx={{border:"0px solid #000"}}>
          {/* <Grid item xs={12} sx={{ border:"0px solid #000", display:"block" }}>
            <Box sx={{borderBottom:"0px solid #9f9f9f",  textAlign:"right" }} >
                
                
                <Tooltip title="Learn more about the ICArchive Worker (docker)" enterNextDelay={300}>
                <Link
                  variant="button"
                  color={colorFramework}
                  href="#"
                  underline="hover"
                  sx={{ my: 1, mx: 1.5, fontSize:"1.2em"}}
                  onClick={() => { props.mainNavClick("Home")}}
                >
                  Framework
                </Link>
                </Tooltip>
                
                <Tooltip title="Learn more about the ICArchive Worker (docker)" enterNextDelay={300}>
                <Link
                  variant="button"
                  color={colorAbout}
                  href="#"
                  underline="hover"
                  sx={{ my: 1, mx: 1.5  , fontSize:"1.2em"}}
                  onClick={() => { props.mainNavClick("About")}}
                >
                  About
                </Link>
                </Tooltip>
                
                <Tooltip title="Learn more about the ICArchive Worker (docker)" enterNextDelay={300}>
                <Link
                  variant="button"
                  color={colorDocumentation}
                  href="#"
                  underline="hover"
                  sx={{ my: 1, mx: 1.5 , fontSize:"1.2em" }}
                  onClick={() => { props.mainNavClick("Documentation")}}
                >
                  Docs
                </Link>
                </Tooltip>
          
            </Box>
          </Grid> */}
          <Grid item xs={12} sx={{display:"flex", justifyContent:"right",border:"0px solid #000", pt:3}}>
            {displayNav}
          </Grid>
        </Grid>
        </nav>
        </Grid>
        </Grid>
        
        {/*
        
        <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
          Login
        </Button>
        */}

      </Toolbar>
    </AppBar>

    

    );
  }// end MainAppBar

  export default MainAppBar ;
  
import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import CircleIcon from '@mui/icons-material/Circle';

// **** CUSTOM IMPORTS
import FullScreenImg from '../nav/FullScreenImg';
import PageNavUpDown from '../nav/PageNavUpDown';

const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges-bw.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};

const FrameworkHow = (props) => {

  console.log ("FrameworkHow RENDERED______")

  return (

    <Paper  elevation={0} sx={{ borderRadius:2, backgroundColor: "#fff", pt:2, pb:2, border:"1px solid #9f9f9f", }} style={styles.paperContainer}>
     
      {/* Hero unit */}
      
      <PageNavUpDown theWhereTo="Framework" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>

      <Box disableGutters component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff",  m: { xs:2, sm:4} ,  p: { xs:2, sm:4} }}>
  
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.secondary"
          gutterBottom
          sx={{
            fontSize: {
              xs: "2em",
              sm: "4em",
            }
            , fontWeight:"bold"
          }}
        >
          How Does it Work?
        </Typography>

        <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{pt:0, pb:2,fontStyle:"italic", fontWeight:"bold"}}>
        We make it easyWe make it easyWe make it easyWe make it easy
        </Typography>
        
        <Typography variant="h7" align="left" color="text.primary" component="p">
          We make it easy We make it easy We make it easy We make it easy We make it easy We make it easy We make it easy We make it easy We make it easy 
        </Typography>

        <Box elevation={0} sx={{ backgroundColor: "#ffffff",  m:"auto", mt:2, mb:0, width:"100%",  justifyContent:"center"}} >

          {/* <FullScreenImg theImgSrc="icpipeline-complete-framework-overview.png" theAltText="How Does it Work? - Create and Deploy dApps to multiple, secured, Internet Computer Environments."/>
 */}

          <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pb:2}}>
            You have:
            </Typography>
            
            <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pb:2, pt:2}}>
            <ul >
            <li><CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />ICArchive serves a crucial role for builders on the Internet Computer by making it easy to push and pull state data from any canister d'app.</li>
            <li><CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />Simply add the data object types in your canister schema(ta).</li>
            <li><CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />Your own dedicated archive canister simply becomes part of your project stack.</li>
            <li><CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />Three clicks to pull a point-in-time snapshot of any canister schema.</li>
            <li><CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />Keep your own browsable library of timestamped snapshots, as many as you need.</li>
            <li><CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />Easily pull off-chain copies of any snapshot, in simple store-anywhere file format (locally, SAN, IPFS, S3/Glacier, etc.)</li>
            <li><CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />Easy "point-and-shoot" restore any snapshot to any canister (with a matching schema, obviously).</li>
            <li><CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />Undo potentially catastrophic errors -- which can happen to anyone including us.</li>
            <li><CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />Roll back to any designated point in time.</li>
            <li><CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />Flash production data sets to your dev/QA/stage canisters (which may be on-chain, or ICPipeline replicas off-chain).</li>
            <li><CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />Keep your pipeline tiers sync'd up.</li>
            <li><CircleIcon sx={{pr:2, pt:1}} fontSize="small"  />Implement organizational retention policies and compliance requirements.</li>
            </ul>
            </Typography>

        </Box>

      </Box>

      <PageNavUpDown theWhereTo="FrameworkHelp" theUpDown="down" theUpDownText="next section" mainNavClick={props.mainNavClick}/>

    </Paper>

  );
} // end FrameworkHow

export default FrameworkHow;

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const LoginICPMNow = (props) => {


  var theBackground = props.theBackground ? props.theBackground : "#ffffff" ;
  var theText = props.theText ? props.theText : "#000000" ;
  var theBorder = props.theBorder ? props.theBorder : "#9f9f9f" ;

    return (

      <Tooltip title="Visit ICPipeline's ICPM" enterNextDelay={300}>
      <Box onClick={() => { window.open ("https://dwkef-qqaaa-aaaah-abkua-cai.ic0.app", "_blank" ) }}  sx={{ backgroundColor:theBackground, border:"1px solid ", 
          borderColor:theBorder,
          cursor:'pointer', 
          borderRadius:2,  mb:1,
          p:1,pl:2, display:"flex", flexGrow:1}} >
          
      <Grid container>
      <Grid item xs={2} sx={{pt:2}} >

        <img
        src={
        "dinfinity.png"
        }
        srcSet={"dinfinity.png?"}
        alt="Dfinity Logo"
        loading="lazy"
        style={{width:"100%", display:"block"}}
        />
        </Grid>
        <Grid item xs={10} >
          <Box sx={{p:2}} >
            <Typography variant="h7" color={theText} sx={{ fontSize: {xs:".8em", sm:'1em'}}} >
            Login to ICPM Now
            </Typography>

          </Box>

        </Grid>
        </Grid>
        </Box>
        </Tooltip>
    );
  }// end LoginICPMNow

  export default LoginICPMNow ;
  
import React, { useState, useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter,useNavigate,useLocation} from 'react-router-dom';

import { green } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// **** CUSTOM IMPORTS

import Stage from './stage_components/Stage';

import AppContext from './nav/AppContext';
import FooterMain from './nav/FooterMain';

const App = () => {



  console.log ("______APP RENDERED______")


  /*Auth*/
  const [isAuthed, setIsAuthed] = useState(false);


  // now we create the theme
  const defaultTheme = createTheme({
    palette: {
      primary: {
        superlight: "#fff6ee",
        light: "#f7e9de",
        main: '#CC6600',
        dark: "#914900",
      },
      secondary: {
        superlight: "#fff6ee",
        light: "#f7e9de",
        main: '#ffffff',
        dark: "#ff850a",
      },
      background: {
        default: '#cc6600',
        paper: '#FFFFFF',
      },
    },
  });

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const copyToClip = async(whatToCopy,setStateVar,newStateVal)=> {
                                          
    await navigator.clipboard.writeText(whatToCopy);
    console.log ("whatToCopy: ", whatToCopy)
    setStateVar ("copied")
    await sleep (2000);
    console.log ("copied");
    setStateVar (newStateVal);
  }

  const userSettings = {
    /*Auth*/
    isAuthedName:isAuthed,
    /*Themes*/
    defaultThemeName: defaultTheme,
    /*AuthFunc*/
    setIsAuthed,
    sleep,
    copyToClip
  }

  
  


  return (
    
    <AppContext.Provider value={userSettings}>
    <ThemeProvider theme={defaultTheme}>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <BrowserRouter initialEntries={['/framework']} initialIndex={0}>   
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
          <CssBaseline />
          <Stage />
  

            
        

      </BrowserRouter>
      </Box>
    </ThemeProvider>
    </AppContext.Provider>

  );
}

export default App;